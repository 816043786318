import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../DiscoverMore2/DiscoverMore2.css";

function DiscoverMore2() {
  return (
    <section>
      <Row>
        <Col className="col1Div p-0">
          <Image
            src="images/Discover/1.jpg"
            fluid
            className="hover1E"
            onMouseEnter={() => alert()}
          />
        </Col>
        <Col className="col1Div p-0">
          <Image src="images/Discover/2.jpg" fluid className="hover1E" />
        </Col>
        <Col className="col1Div p-0">
          <Image src="images/Discover/3.jpg" fluid className="hover1E" />
        </Col>
        <Col className="col1Div p-0">
          <Image src="images/Discover/4.jpg" fluid className="hover1E" />
        </Col>
      </Row>
    </section>
  );
}

export default DiscoverMore2;
