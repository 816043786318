import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";

import ShopProvider from "./context/shopContext";
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import ProductsDetails from "./pages/ProductsDetails";
import Cart from "../src/components/Cart/Cart";

import { Scrollbars } from "react-custom-scrollbars";

function App() {
  return (
    <ShopProvider>
      <Router>
        <Header />
        <Cart />
        <main>
          <Route path="/product/:id" component={ProductsDetails} exact></Route>
          <Route path="/home2" component={Home2} exact></Route>
          <Route path="/" component={Home} exact></Route>
        </main>
      </Router>
    </ShopProvider>
  );
}

export default App;
