import React, { useContext, useState, useEffect } from "react";
import { Nav, Navbar, Container, Image, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header/Header.css";

import { Menu as MenuIcon } from "@material-ui/icons";
import { Bag, Search } from "react-bootstrap-icons";

function Header() {
  const [offset, setOffset] = useState(0);
  const { openCart } = useContext(ShopContext);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return (
    <header>
      <Navbar
        className={offset < 300 ? "nav-bg" : "nav-bg2"}
        expand="lg"
        collapseOnSelect
        fixed="top"
        onMouseEnter={(e) => setOffset(400)}
        onMouseLeave={(e) => setOffset(window.pageYOffset)}
      >
        <Container>
          <LinkContainer to="/home2">
            <Navbar.Brand>
              <Image
                src="images/logo/logoF.png"
                alt="img"
                fluid
                width="120px"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {/* <div id="wrap">
                <form action="">
                  <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="What're you looking for ?"
                    autoComplete="off"
                  />
                  <input id="search_submit" value="Rechercher" type="submit" />
                </form>
              </div> */}
              <NavDropdown
                title={
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    HOME
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <LinkContainer to="/">
                  <NavDropdown.Item>HOME 1</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/home2">
                  <NavDropdown.Item>HOME 2</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/">
                <Nav.Link>
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    FORMALS
                  </span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                <Nav.Link>
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    CASUALS
                  </span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                <Nav.Link>
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    PARTY
                  </span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                <Nav.Link>
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    COMFORT
                  </span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                <Nav.Link>
                  <span className={offset < 300 ? "whiteC" : "blackC"}>
                    WHATS TRENDING
                  </span>
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              <Nav.Link>
                <div id="wrap">
                  <form action="">
                    <input
                      id="search"
                      name="search"
                      type="text"
                      placeholder="What're you looking for ?"
                      autoComplete="off"
                    />
                    <input
                      id="search_submit"
                      value="Rechercher"
                      type="submit"
                    />
                  </form>
                </div>
              </Nav.Link>
              <Nav.Link>
                <span
                  className={offset < 300 ? "whiteC" : "blackC"}
                  onClick={() => openCart()}
                >
                  <Bag style={{ marginTop: "15px" }} />
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
