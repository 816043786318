import React from "react";
import "../ShopByInstagram/ShopByInstagram.css";
import { Row, Col, Image, Container } from "react-bootstrap";

function ShopByInstagram() {
  return (
    <section className="pb-5" style={{ background: "#e6e6e6" }}>
      <Container>
        <Row className="text-center p-5">
          <h4 style={{ fontFamily: "sans-serif" }}>Trending On Instagram</h4>
        </Row>
        <Row className="py-2" style={{ width: "90%", margin: "auto" }}>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/1.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/2.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              src="images/instagram/3.jpg"
              fluid
              style={{ border: "2px solid #808080" }}
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/4.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/5.jpg"
              fluid
            ></Image>
          </Col>
        </Row>
        <Row className="py-2" style={{ width: "90%", margin: "auto" }}>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/6.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/7.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/8.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/9.jpg"
              fluid
            ></Image>
          </Col>
          <Col className="colDiv2">
            <Image
              className="hoverE2"
              src="images/instagram/10.jpg"
              fluid
            ></Image>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ShopByInstagram;
