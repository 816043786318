import React from "react";
import { Carousel } from "react-bootstrap";
import "../Carousal2/Carousal2.css";

function CarousalComponent2() {
  return (
    <section>
      <div className="p-3">
        <Carousel variant="dark" className="p-5" controls={false}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day</h3>
              <p style={{ color: "black" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day 2</h3>
              <p style={{ color: "black" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day 3</h3>
              <p style={{ color: "black" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default CarousalComponent2;
