import React, { useContext, useEffect } from "react";
import { Image, Container } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import CarousalComponent from "../components/Carousal/CarousalComponent";

import Jumbotrons from "../components/Jumbotrons/Jumbotrons";

import ProductParent from "../components/ProductParent/ProductParent";

import VideoSection from "../components/VideoSection/VideoSection";
import DiscoverMore2 from "../components/DiscoverMore2/DiscoverMore2";
import Blogs from "../components/Blogs/Blogs";
import CarousalComponent2 from "../components/Carousal2/CarousalComponent2";
import ShopByInstagram from "../components/ShopByInstagram/ShopByInstagram";

function Home() {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <>
      <VideoSection url="images/banners/BannerVideo.mp4" />

      <Container>
        <Jumbotrons />
        <Image src="images/dividers/divider.jpg" className="mt-2" fluid />
      </Container>

      {/* <DiscoverMore2 /> */}

      <ProductParent products={products} />
      <Container>
        <CarousalComponent2 />
        <Blogs />
      </Container>
      <ShopByInstagram />
      <Image src="images/footer/footer.jpg" fluid></Image>
    </>
  );
}

export default Home;
