import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../../components/ProductParent/ProductParent.css";
import FilterShoes from "../FilterShoes/FilterShoes";

function ProductParent({ products }) {
  const [filterProducts, setFilterProducts] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setFilterProducts(products);
  }, []);

  const handleChange = (f, l) => {
    setFilterProducts(products.slice(f, l));
  };

  const handleReset = () => {
    setFilterProducts(products.slice(10, 20));
  };

  return (
    <>
      <section>
        <Row>
          <Col className="col1Div p-0">
            <Image
              src="images/Discover/1.jpg"
              fluid
              className="hover1E"
              onMouseEnter={(e) => handleChange(10, 15)}
              onMouseLeave={!active && handleReset}
              onClick={(e) => setActive((prev) => !prev)}
            />
          </Col>
          <Col className="col1Div p-0">
            <Image
              src="images/Discover/2.jpg"
              fluid
              className="hover1E"
              onMouseEnter={(e) => handleChange(15, 20)}
              onMouseLeave={!active && handleReset}
              onClick={(e) => setActive((prev) => !prev)}
            />
          </Col>
          <Col className="col1Div p-0">
            <Image
              src="images/Discover/3.jpg"
              fluid
              className="hover1E"
              onMouseEnter={(e) => handleChange(0, 5)}
              onMouseLeave={!active && handleReset}
              onClick={(e) => setActive((prev) => !prev)}
            />
          </Col>
          <Col className="col1Div p-0">
            <Image
              src="images/Discover/4.jpg"
              fluid
              className="hover1E"
              onMouseEnter={(e) => handleChange(5, 10)}
              onMouseLeave={!active && handleReset}
              onClick={(e) => setActive((prev) => !prev)}
            />
          </Col>
        </Row>
      </section>
      <FilterShoes filterProducts={filterProducts} />
    </>
  );
}

export default ProductParent;
