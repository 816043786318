import React, { useEffect } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import Product from "../Product/Product";

function FilterShoes({ filterProducts }) {
  return (
    <section>
      <div
        style={{
          backgroundImage: `url("images/Discover/pattren.png")`,
          backgroundSize: "contain",
          width: "100%",
          // height: "107%",
          opacity: "0.8",
          // position: "absolute",
          // zIndex: "-1",
        }}
      >
        <Container className="py-3" style={{ backgroundColor: "white" }}>
          <Row className="text-center">
            <Col className="p-4">
              <h3>EZOK New Arrivals</h3>
            </Col>
          </Row>
          <Row md={5}>
            {filterProducts.map((product) => {
              return (
                <Col key={product.id}>
                  <Product product={product} />
                </Col>
              );
            })}
          </Row>
          <Row className="text-center py-5">
            <Col>
              <Button
                variant="outline-dark"
                className="px-5 py-0 btn-lg rounded-0 "
              >
                Explore More
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default FilterShoes;
